.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Smooth scrolling für die gesamte Seite */
html {
  scroll-behavior: smooth;
}

/* Scrollleiste für Webkit-Browser (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Standardbreite der Scrollleiste */
}

::-webkit-scrollbar-track {
  background: #1D2433; /* Hintergrund der Scrollleiste */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #3A4750; /* Standardfarbe des Scrollthumbs */
  border-radius: 10px; /* Runde Ecken */
  border: 2px solid #1D2433;
  transition: all 0.4s ease; /* Sanfter Übergang für Hover- und Größenänderungen */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #556678; /* Hover-Farbe des Scrollthumbs */
  width: 12px; /* Vergrößert den Thumb leicht beim Hover */
  border-radius: 12px; /* Runde Ecken beim Hover für einen smoothen Look */
}

/* Für Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #3A4750 #1D2433;
}

.github-stats-container {
  display: flex;
  justify-content: space-around;
  margin: 40px 0;
}

.stat-card {
  padding: 20px;
  background: linear-gradient(135deg, #4e54c8, #8f94fb);
  color: #fff;
  border-radius: 15px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
  width: 200px; /* Set a fixed width */
}

.stat-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.3);
}

.stat-title {
  font-size: 1.5em;
  font-weight: bold;
}

.stat-value {
  font-size: 2.5em;
  margin-top: 10px;
  color: #ffd700; /* Gold color */
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.menu-bar {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px;
  display: inline-flex;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(10px);
  padding: 10px;
  z-index: 1000; /* Stellt sicher, dass die Navigationsleiste über anderen Inhalten bleibt */
}

.menu-bar ul {
  display: flex;
  padding: 0;
  margin: 0;
}

.menu-bar li {
  list-style: none;
  margin: 0 8px;
  position: relative;
}

.menu-bar a {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  color: white;
  transition: color 0.2s;
}

.menu-bar a:hover {
  color: black;
  background: linear-gradient(to bottom, #e8edec, #d2d1d3);
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.bg-[#1D2433] {
  background-color: #1D2433; /* Dunkler Hintergrund für den Dark Mode */
}

.text-white {
  color: white; /* Weißer Text für Dark Mode */
}

button {
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  transform: scale(1.05);
}

textarea {
  background-color: #282c34; /* Dark Background für das Textfeld */
  color: white; /* Weißer Text im Textfeld */
  border: 1px solid #3a4750; /* Border für das Textfeld im Dark Mode */
}

textarea:focus {
  border-color: #6a80ff; /* Fokusborder in blauer Farbe */
}

button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 0, 255, 0.5);
}

/* Für die Übergänge und Animation */
.motion-div {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* Standardfarbe */
.menu-bar .sparkle-icon a {
  transition: all 0.3s ease;
}

/* Gelbe Farbe beim Hover über das Sparkles-Symbol */
.menu-bar .sparkle-icon a:hover {
  color: yellow;  /* Gelb beim Hover */
}

.menu-bar a:hover {
  color: black;
  background: linear-gradient(to bottom, #e8edec, #d2d1d3);
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

/* Optional: Für einen Smooth-Scale-Effekt */
.menu-bar .sparkle-icon a:hover {
  transform: scale(1.2);
}
